import { graphql } from 'gatsby'
import styled from 'styled-components'
import React from 'react'
import Navbar from '../components/Navbar'
import PolygonBanner, { StyledPolygonBanner } from '../components/PolygonBanner'
import { breakpoints, colors } from '../theme'
import { ParallaxProvider } from 'react-scroll-parallax'
import { StyledButton } from '@monbanquet/crumble'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import ParallaxShape from '../components/ParallaxShape'
import Link from '../components/Link'

const ClientsPage = ({ data: { page, clientsList } }) => {
  const content = page.edges[0].node
  const clients = clientsList.edges
  // TODO add seo keywords ...
  return (
    <StyledClientsPage>
      <Helmet
        title={content.title}
        meta={[{ name: 'description', content: content.subTitle }]}
      />
      <Navbar />
      <ParallaxProvider>
        <div className="page-content">
          <PolygonBanner picture={content.picture} color="#F5F5F5">
            <h1>{content.title}</h1>
            <h3>{content.subTitle}</h3>
          </PolygonBanner>
          <div className="container">
            <ParallaxShape
              x={85}
              y={0}
              style={{ zIndex: '-2' }}
              shapeStyle={{
                width: '400px',
                height: '200px',
                border: '140px solid ' + colors.brand.beige,
                borderBottom: 'none',
                borderTopLeftRadius: '400px',
                borderTopRightRadius: '400px',
                transform: 'matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)',
                opacity: '0.5',
              }}
            />
            <ParallaxShape
              x={-10}
              y={30}
              shapeStyle={{
                width: '400px',
                height: '400px',
                border: '140px solid ' + colors.brand.beige,
                borderRadius: '50%',
                opacity: '0.5',
              }}
            />
            <div className="nos-clients-container">
              {clients &&
                clients.map(
                  (client, i) =>
                    client.node.pictures && (
                      <ClientCard client={client.node} key={i} />
                    ),
                )}
            </div>
          </div>
        </div>
        <Footer />
      </ParallaxProvider>
    </StyledClientsPage>
  )
}

const ClientCard = ({ client }) => {
  return (
    <Link to={client && `/${client.slug}`} className="client-card">
      <article>
        <figure>
          <Img
            fluid={client.pictures[0].fluid}
            alt={client.name}
            loading="lazy"
          />
        </figure>
        <div className="client-card__meta">
          <div className="client-card__row">
            <h3 className="client-card__title">{client.name}</h3>
          </div>
          <p>
            {client.type}&nbsp;&bull;&nbsp;
            {`${client.numberOfPeople} personnes`}
          </p>
        </div>
      </article>
    </Link>
  )
}
const StyledClientsPage = styled.div`
  ${StyledPolygonBanner} {
    h1 {
      font-size: 60px;
      font-weight: 500;
      line-height: 3.8rem;
      height: 70px;
    }

    h3 {
      font-size: 50px;
      font-weight: normal;
      color: ${colors.brand.normal};
      margin-bottom: 100px;
    }
  }
  .container {
    position: relative;
    overflow: hidden;
  }
  .nos-clients-container {
    position: relative;
    margin: 0 auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    max-width: 82.5rem;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    justify-content: center;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    width: calc(100% - 12vw);
    margin-top: 100px;
    a {
      cursor: pointer;
    }
    figure {
      margin: 0;
    }
    .client-card {
      display: block;
      z-index: 1;
      position: relative;
      background: #fff;
      border: 1px solid #eef3f6;
      -webkit-transition: all 0.25s ease;
      transition: all 0.25s ease;
      margin-bottom: 1.25rem;
      width: 100%;
    }
    .client-card:hover {
      box-shadow: 0 0.125rem 0.5rem 0.25rem rgba(153, 155, 168, 0.3);
    }
    .client-card__meta {
      padding: 1.875rem;
    }
    .client-card__row {
      display: flex;
      justify-content: space-between;
    }
    .client-card__title {
      display: inline-block;
      font-size: 1.75em;
      line-height: 2rem;
      font-family: Walsheim, sans-serif;
      color: #343851;
    }
    .client-card__meta p {
      font-family: inherit;
      margin-top: 0;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 0.9375rem;
      color: #696c7b;
    }
    @media (max-width: ${breakpoints.tablet}px) and (min-width: ${breakpoints.mobile}px) {
      .client-card {
        width: 48.4375%;
        margin-right: 1.125%;
      }
    }
    @media (min-width: ${breakpoints.tablet}px) {
      .client-card {
        width: 48.4375%;
        margin-right: 1.125%;
        margin-bottom: 2.5rem;
      }
    }
  }

  @media (max-width: ${breakpoints.desktop}px) {
    .children-content {
      text-align: center;

      h1 {
        font-size: 35px;
        line-height: 40px;
      }

      h3 {
        font-size: 25px;
        font-weight: normal;
      }
      ${StyledButton} {
        margin-top: 15px;
        padding: 0 26px;
      }
    }
  }
  ${StyledButton} {
    margin-top: 30px;
    border-radius: 100px;
    background-color: ${colors.secondary.yellow};
    color: ${colors.brand.black};
    height: 66px;
    font-size: 19px;
    padding: 22px 64px;
    text-transform: none;
  }
`

export default ClientsPage

export const query = graphql`
  {
    page: allContentfulBanner(filter: { fixedId: { eq: "nos-clients" } }) {
      edges {
        node {
          id
          title
          subTitle
          picture {
            fluid(maxHeight: 500, maxWidth: 800, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    clientsList: allContentfulEventShowcase {
      edges {
        node {
          slug
          name
          type
          numberOfPeople
          pictures {
            fluid(maxHeight: 500, maxWidth: 800, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
